import { Game } from "./Game";
import { Upgrade } from "./Upgrade";
import data from "./upgrades.json";

declare global {
    interface Window {
        purchase: any;
        game: Game;
    }
}

(() => {
    let newUpgrades: Upgrade[] = [];
    data.upgrades.forEach((upgrade) => {
        let newUpgrade: Upgrade = new Upgrade(
            upgrade.name,
            upgrade.displayName,
            upgrade.price,
            upgrade.perClick,
            upgrade.priceIncrease,
            0,
            upgrade.auto
        );
        newUpgrades.push(newUpgrade);
    });

    let game: Game = new Game(
        "mine",
        "currency-amount-display",
        "upgrades-list",
        newUpgrades,
        "lifetime-display",
        "currency-p-click-display",
        "idle-click-display",
        "purchases-amount",
        "clicks-display",
        "manual-save",
        "canvas",
        "coin"
    );
    window.game = game;
    game.load();
    game.save();
    let countdown: number = 11;
    setInterval(() => {
        if (countdown <= 0) {
            countdown = 11;
        }
        countdown -= 1;
        document.getElementById("autosave-timer").innerText = String(countdown);
    }, 1000);
    setInterval(() => {
        game.idleMine();
    }, 1000);
    setInterval(() => {
        game.drawParticles();
    }, 15);
    setInterval(() => {
        game.save();
        game.refresh();
        game.particleCollect();
    }, 10000);
})();
