export const loadWithDefault = (index: string, defaultValue: number) => {
    let val: number;
    val = Number(localStorage.getItem(index));
    if (val == 0) {
        val = defaultValue;
    }
    return val;
};

export const getUnixTime = () => {
    return Math.floor(Date.now() / 1000);
}