{
    "upgrades": [
        {
            "name": "basic-upgrade",
            "displayName": "Basic Upgrade",
            "price": 10,
            "perClick": 1,
            "priceIncrease": 1.015,
            "auto": false
        },
        {
            "name": "better-upgrade",
            "displayName": "Better Upgrade",
            "price": 100,
            "perClick": 10,
            "priceIncrease": 1.025,
            "auto": false
        },
        {
            "name": "basic-auto-upgrade",
            "displayName": "Basic Auto Upgrade",
            "price": 10,
            "perClick": 1,
            "priceIncrease": 1.015,
            "auto": true
        }
    ]
}
