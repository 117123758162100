import { Upgrade } from "./Upgrade";
import { loadWithDefault, getUnixTime } from "./utils";
import { Particle } from "./Particle";

export class Game {
    currency: number;
    currencyPerClick: number;
    idleClick: number;
    upgrades: Upgrade[];
    lifetimeEarnings: number;
    purchasesAmount: number;
    totalClicks: number;
    currencyAmountDisplay: HTMLElement;
    mineButton: HTMLElement;
    upgradesList: HTMLElement;
    lifetimeDisplay: HTMLElement;
    currencyPerClickDisplay: HTMLElement;
    idleClickDisplay: HTMLElement;
    purchasesAmountDisplay: HTMLElement;
    clicksDisplay: HTMLElement;
    manualSaveButton: HTMLElement;
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    latestPurchases: Upgrade[];
    clickEffectImage: CanvasImageSource;
    particles: Particle[];

    constructor(
        mineButton: string,
        currencyAmountDisplay: string,
        upgradesList: string,
        upgrades: Upgrade[],
        lifetimeDisplay: string,
        currencyPerClickDisplay: string,
        idleClickDisplay: string,
        purchasesAmountDisplay: string,
        clicksDisplay: string,
        manualSaveButton: string,
        canvas: string,
        clickEffectImage: string
    ) {
        this.currencyAmountDisplay = document.getElementById(
            currencyAmountDisplay
        );
        this.mineButton = document.getElementById(mineButton);
        this.mineButton.onclick = () => {
            this.mine();
        };
        if (localStorage.getItem("purchasesLoaded") !== "true") {
            localStorage.setItem("startingPurchases", JSON.stringify(upgrades));
        }
        localStorage.setItem("purchasesLoaded", "true");
        this.upgrades = upgrades;
        this.latestPurchases = upgrades;
        this.upgradesList = document.getElementById(upgradesList);
        this.lifetimeDisplay = document.getElementById(lifetimeDisplay);
        this.currencyPerClickDisplay = document.getElementById(
            currencyPerClickDisplay
        );
        this.idleClickDisplay = document.getElementById(idleClickDisplay);
        this.purchasesAmountDisplay = document.getElementById(
            purchasesAmountDisplay
        );
        this.clicksDisplay = document.getElementById(clicksDisplay);
        this.manualSaveButton = document.getElementById(manualSaveButton);
        this.manualSaveButton.onclick = () => {
            this.save();
        };
        this.canvas = document.getElementById(canvas) as HTMLCanvasElement;
        this.context = this.canvas.getContext("2d");
        this.canvas.height = window.innerHeight;
        this.canvas.width = window.innerWidth;
        this.clickEffectImage = document.getElementById(clickEffectImage) as CanvasImageSource;

        this.context.drawImage(this.clickEffectImage, 600, 400);
        this.particles = [];
        this.context.globalAlpha = 0.75;
    }

    refresh() {
        this.currencyAmountDisplay.innerText = String(this.currency);
        this.upgradesList.innerHTML = "";
        this.upgrades.forEach((upgrade) => {
            this.upgradesList.innerHTML += upgrade.getAsHtml();
        });
        this.lifetimeDisplay.innerText = String(this.lifetimeEarnings);
        this.currencyPerClickDisplay.innerText = String(this.currencyPerClick);
        this.idleClickDisplay.innerText = String(this.idleClick);
        this.purchasesAmountDisplay.innerText = String(this.purchasesAmount);
        this.clicksDisplay.innerText = String(this.totalClicks);
    }

    spawnParticle() {
        let unixtime = getUnixTime();
        let newParticle = { 
            x: 0,
            y: Math.random() * this.canvas.height,
            xs: 8,
            ys: 8,
            spawned: unixtime
        };
        this.particles.push(newParticle);
    }

    drawParticles() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for(let c = 0; c < this.particles.length; c++) {
            let p = this.particles[c];
            this.context.drawImage(this.clickEffectImage, p.x, p.y, Number(this.clickEffectImage.height)/5, Number(this.clickEffectImage.width)/5);
            p.x += p.xs;
        }
    }

    particleCollect() {
        let unixtime = getUnixTime();
        this.particles.forEach((particle, index) => {
            if (particle.spawned + 30 > unixtime) {
                this.particles.splice(index, 1);
            }
        });
    }

    purchase(name: string) {
        this.upgrades.forEach((upgrade) => {
            if (upgrade.name == name) {
                if (this.currency >= upgrade.price) {
                    this.currency -= upgrade.price;
                    upgrade.handlePurchased();
                    if (upgrade.auto == true) {
                        this.idleClick += upgrade.perClick;
                    } else {
                        this.currencyPerClick = this.currencyPerClick + upgrade.perClick;
                    }
                    this.purchasesAmount += 1;
                    localStorage.setItem("hasPurchased", "true");
                }
            }
        });
        this.refresh();
    }

    recalculateUpgrades() {
        let newIdleClick = 0;
        let newClick = 1;
        this.upgrades.forEach((upgrade, index) => {
            let newVersionUpgrade: Upgrade;
            let oldTimesPurchased = upgrade.timesPurchased;
            
            this.latestPurchases.forEach((lUpgrade) => {
                if (lUpgrade.name == upgrade.name) {
                    newVersionUpgrade = lUpgrade;
                }
            });
            let totalCost = 0;
            while (newVersionUpgrade.timesPurchased < oldTimesPurchased) {
                totalCost += newVersionUpgrade.price;
                newVersionUpgrade.handlePurchased();
                if (newVersionUpgrade.auto == true) {
                    newIdleClick += newVersionUpgrade.perClick;
                } else {
                    newClick += newVersionUpgrade.perClick;
                }
            }
            this.upgrades[index] = newVersionUpgrade;
        });
        this.idleClick = newIdleClick;
        this.currencyPerClick = newClick;

    }

    save() {
        localStorage.setItem("currency", String(this.currency));
        localStorage.setItem("currencyPerClick", String(this.currencyPerClick));
        localStorage.setItem("idleClick", String(this.idleClick));
        localStorage.setItem("upgrades", JSON.stringify(this.upgrades));
        
        localStorage.setItem("lifetimeEarnings", String(this.lifetimeEarnings));
        localStorage.setItem("purchasesAmount", String(this.purchasesAmount));
        localStorage.setItem("totalClicks", String(this.totalClicks));
        this.refresh();
    }

    mine() {
        this.currency += this.currencyPerClick;
        this.lifetimeEarnings += this.currencyPerClick;
        this.totalClicks += 1;
        this.spawnParticle();
        this.refresh();
    }

    idleMine() {
        this.currency += this.idleClick;
        this.lifetimeEarnings += this.idleClick;
        if (this.idleClick > 0) {
            this.spawnParticle();
        }
        this.refresh();
    }

    load() {
        this.currency = loadWithDefault("currency", 0);
        this.currencyPerClick = loadWithDefault("currencyPerClick", 1);
        this.idleClick = loadWithDefault("idleClick", 0);
        this.lifetimeEarnings = loadWithDefault("lifetimeEarnings", 0);
        this.purchasesAmount = loadWithDefault("purchasesAmount", 0);
        this.totalClicks = loadWithDefault("totalClicks", 0);
        if (localStorage.getItem("hasPurchased") == "true") {
            let loadedRawUpgrades: Upgrade[] = JSON.parse(
                localStorage.getItem("upgrades")
            );


            let fixedLoadedUpgrades: Upgrade[] = [];
            loadedRawUpgrades.forEach((rawUpgrade) => {
                let fixedUpgrade: Upgrade = new Upgrade(
                    rawUpgrade.name,
                    rawUpgrade.displayName,
                    rawUpgrade.price,
                    rawUpgrade.perClick,
                    rawUpgrade.priceIncrease,
                    rawUpgrade.timesPurchased,
                    rawUpgrade.auto
                );
                fixedLoadedUpgrades.push(fixedUpgrade);
            });

            let difference = this.upgrades.filter(
                (x) => !fixedLoadedUpgrades.some((y) => y.name === x.name)
            );

            this.upgrades = fixedLoadedUpgrades.concat(difference);

            let starterUpgrades: Upgrade[] = JSON.parse(
                localStorage.getItem("startingPurchases")
            );
            if (starterUpgrades != this.latestPurchases) {
                this.recalculateUpgrades();
            }
        }
        this.refresh();
    }
}
