export class Upgrade {
    name: string;
    displayName: string;
    price: number;
    perClick: number;
    priceIncrease: number;
    timesPurchased: number;
    auto: boolean;

    constructor(
        name: string,
        displayName: string,
        price: number,
        perClick: number,
        priceIncrease: number,
        timesPurchased: number,
        auto: boolean
    ) {
        this.name = name;
        this.displayName = displayName;
        this.price = price;
        this.perClick = perClick;
        this.priceIncrease = priceIncrease;
        this.timesPurchased = timesPurchased;
        this.auto = auto;
    }

    handlePurchased() {
        this.increasePrice();
        this.incrementPurchased();
    }

    incrementPurchased() {
        this.timesPurchased += 1;
    }

    increasePrice() {
        this.price = Math.round(
            this.price * (this.priceIncrease ** this.timesPurchased)
        );
    }

    getAsHtml() {
        return `
        <tr id="${this.name}">
        <td>${this.displayName}</td>
        <td>${this.price}</td>
        <td><button onclick="game.purchase('${this.name}')">Purchase</button></td>
        </tr>
    `;
    }
}
